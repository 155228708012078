export function userSportApi() {
  const API_VER = useRuntimeConfig().API_VER_1
  return {
    GET_LINK_A_SPORT: `${API_VER}/athena/sportUrl`,
    GET_LINK_C_SPORT: `${API_VER}/athena/sportUrl`,
    GET_LINK_K_SPORT: `${API_VER}/tp/ksportUrl`,
    GET_LINK_VOLTA: `${API_VER}/tp/ksportUrl?sportType=1_10`,
    GET_LINK_I_SPORT: `${API_VER}/tp/imsportUrl`,
    GET_LINK_P_SPORT: `${API_VER}/tp/ssportUrl`,
    GET_LINK_S_SPORT: `${API_VER}/athena/sportUrl`,
    GET_LINK_M_SPORT: `${API_VER}/tp/imsportUrl`,
    GET_LINK_T_SPORT: `${API_VER}/tp/tsportUrl`,
    GET_LINK_E_SPORT: `${API_VER}/athena/esportsUrl`,
    GET_LINK_VIRTUAL: `${API_VER}/athena/virtualSportUrl`
  }
}
